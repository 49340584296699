import React, { useState, useContext } from "react";
import "./mainBanner.css";
import { Carousel, Modal, Button } from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";
import NewEmail from "../../assets/icons/email.png";

export const MainBanner = () => {
  const { bannerData, dataLoading, dataError } = useContext(AuthContext);
  const [modalShow, setModalShow] = useState(false);

  const banners = bannerData.filter((item) => item.type === "banner");
console.log("banner",banners);
  if (dataLoading) return <div>Loading...</div>;
  if (dataError) return <div>Error: {dataError.message}</div>;
  if (!banners.length) return <div>No banner data available</div>;

  return (
    <div className="banner-carousel">
      <Carousel controls={true} indicators={false}>
        {banners.map((banner, index) => (
          <Carousel.Item key={index}>
            <div className="carousel-item-content">
              <div className="carousel-text">
                <h2>{banner.title}</h2>
                <p>{banner.description}</p>
                <button
                  className="subscribe-button"
                  onClick={() => setModalShow(true)}
                  style={{
                    
                  }}
                >
                  Subscribe
                </button>
              </div>
              <div className="carousel-image">
                <img
                  className="d-block w-100"
                  src={banner.imageurl}
                  alt={`Slide ${index + 1}`}
                />
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Title id="contained-modal-title-vcenter" className="modal-title-custom">
          <div
            style={{
              padding: "8px",
              fontWeight: "bold",
              color: "#9c4a1a",
            }}
          >
            Subscribe for updates
          </div>
        </Modal.Title>
        <Modal.Body>
          <div className="banner-buttons">
            <div>
              <Button variant="">
                <img src={NewEmail} width={"40px"} height={"40px"} />
              </Button>
            </div>
            <div>
              <input
                placeholder="Your email address"
                style={{
                  width: "80%",
                  border: "none",
                  marginTop: "10px",
                  fontSize: "16px",
                  fontWeight: "bolder",
                  color: "#CF7D08",
                  outline: "none",
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#9c4a1a",
              outline: "none",
              border: "none",
            }}
            onClick={() => setModalShow(false)}
          >
            Subscribe
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
