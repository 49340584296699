import React from "react";
import { MDBIcon } from "mdb-react-ui-kit";
import { ReactComponent as FacebookIcon } from "../../assets/icons/Facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/Instagram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/Twitter.svg";
import { ReactComponent as GoogleIcon } from "../../assets/icons/Google.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/LinkedIn.svg";
import "./Contact.css";

const ContactUs = () => {
  return (
    <div className="contact-container">
      <h2 className="text-center">Contact Us</h2>
      <div className="contact-content">
      <div className="contact-section">
  <h4 className="text-uppercase fw-bold mb-4">Contact Information</h4>
  <div className="contact-item">
    <MDBIcon color="secondary" icon="home" className="mdb-icon me-2" />
    <p>
      T-73, 3rd Floor, Singh Sabha Marg, near Patel Gym, Block B, Baljit Nagar, Patel Nagar, New Delhi, Delhi, 110008
    </p>
  </div>
  <div className="contact-item">
    <MDBIcon color="secondary" icon="envelope" className="mdb-icon me-3" />
    <p>themilletodyssey@gmail.com</p>
  </div>
  <div className="contact-item">
    <MDBIcon color="secondary" icon="phone" className="mdb-icon me-3" />
    <p>+91-8051068546</p>
  </div>
</div>

        {/* <div className="contact-section">
          <h4>Follow Us</h4>
          <div className="contact-icons">
            <a href="https://www.facebook.com" className="me-4">
              <FacebookIcon width={40} height={40} color="black" />
            </a>
            <a href="https://www.twitter.com" className="me-4">
              <TwitterIcon width={40} height={40} color="black" />
            </a>
            <a href="https://www.google.com" className="me-4">
              <GoogleIcon width={40} height={40} color="black" />
            </a>
            <a href="https://www.instagram.com" className="me-4">
              <InstagramIcon width={40} height={40} color="black" />
            </a>
            <a href="https://www.linkedin.com" className="me-4">
              <LinkedinIcon width={40} height={40} color="black" />
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ContactUs;
